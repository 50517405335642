import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { NextBtn } from 'public/assets';
import { DEEP_LINK } from '@common/values';
import { openDeepLink } from '@common/utils';

interface IViewInAppButton {
  deepLink?: string;
}

const ViewInAppButton = ({
  deepLink = DEEP_LINK.NEW_FEATURE_OPEN_APP,
}: IViewInAppButton) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleOnClick = () => {
    openDeepLink(router, deepLink);
  };

  return (
    <button css={wrapper} onClick={handleOnClick}>
      <a className="button-text">
        <strong className="strong-text">
          {t('common_screen_button_open_app')}
        </strong>
      </a>
      <NextBtn width={16} height={16} stroke={theme.colors.white} />
    </button>
  );
};

const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  width: 100%;
  height: 3.5em;
  cursor: pointer;
  background: ${theme.colors.primary500};
  color: ${theme.colors.white};
  font-family: Apple SD Gothic Neo;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;

  .button-text {
    padding-top: 0.2em;

    .strong-text {
      font-weight: 700;
      font-family: Apple SD Gothic Neo;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }
`;

export default ViewInAppButton;
