import { SerializedStyles, css } from '@emotion/react';

export type ColorType = keyof typeof colors;

const colors = {
  primary900: '#22004a',
  primary800: '#32036B',
  primary700: '#450891',
  primary600: '#631FB7',
  primary500: '#753BBD',
  primary400: '#8F58D5',
  primary300: '#B37AFC',
  primary200: '#DEBFFF',
  primary100: '#F3E8FF',

  emerald600: '#0AB3BA',
  emerald500: '#2CCCD3',
  emerald100: '#DEF7F9',

  danger600: '#DB2B00',
  danger500: '#FF5B56',

  point100: '#FFFE79',
  point300: '#FFD31A',
  point500: '#DD9F1E',

  red100: '#FDEBF0',
  red500: '#DD3955',

  white: '#FFFFFF',
  gray50: '#FAFAFA',
  gray100: '#F5F5F5',
  gray200: '#EDEDEE',
  gray300: '#DDDEE1',
  gray400: '#C6C8CC',
  gray500: '#A4A7AD',
  gray600: '#7E8185',
  gray700: '#57595E',
  gray800: '#3F4142',
  gray900: '#242526',
  black: '#1A1A1A',
  darkBlack: '#000000',
};

const font = {
  // fontV1
  title1: '700 1.5em Apple SD Gothic Neo',
  title2Bold: '700 1.25em Apple SD Gothic Neo',
  title2Normal: '400 1.25em Apple SD Gothic Neo',
  body1Bold: '700 1em Apple SD Gothic Neo',
  body1Normal: '400 1em Apple SD Gothic Neo',
  body2Bold: '700 0.875em Apple SD Gothic Neo',
  body2Normal: '400 0.875em Apple SD Gothic Neo',
  body3Bold: '700 0.75em Apple SD Gothic Neo',
  body3Normal: '400 0.75em Apple SD Gothic Neo',
  // fontV2
  header: {
    headline3: css`
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.01875rem;
    `,
    headline2: css`
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: -0.01875rem;
    `,
    headline1: css`
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.625rem;
      letter-spacing: -0.01875rem;
    `,
    navigation: css`
      font-style: normal;
      font-weight: 600;
      font-size: 1.0625rem;
      line-height: 1.375rem;
      letter-spacing: -0.01875rem;
    `,
    subhead3: css`
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.375rem;
      letter-spacing: -0.01875rem;
    `,
    subhead2: css`
      font-style: normal;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: -0.01875rem;
    `,
    subhead1: css`
      font-style: normal;
      font-weight: 700;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      letter-spacing: -0.01875rem;
    `,
    subhead0: css`
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: -0.0187rem;
    `,
  },
  body: {
    body4: css`
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.375rem;
      letter-spacing: -0.01875rem;
    `,
    body3: css`
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.375rem;
      letter-spacing: -0.01875rem;
    `,
    body2: css`
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: -0.01875rem;
    `,
    body1: css`
      font-style: normal;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      letter-spacing: -0.01875rem;
    `,
    caption: css`
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: -0.01875rem;
    `,
  },
};

const fontFamily = `
'Pretendard Variable',
  'Apple SD Gothic Neo', 
  -apple-system, 
  BlinkMacSystemFont, 
  helvetica, 
  Arial, 
  sans-serif
`;

const fontV3 = {
  heading: {
    heading01: css`
      font-family: ${fontFamily};
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.125rem;
    `,
    heading02: css`
      font-family: ${fontFamily};
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.875rem;
    `,
    heading03: css`
      font-family: ${fontFamily};
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.75rem;
    `,
    heading04: css`
      font-family: ${fontFamily};
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.625rem;
    `,
    heading05: css`
      font-family: ${fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    `,
  },
  body: {
    body01Bold: css`
      font-family: ${fontFamily};
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.875rem;
    `,
    body01Reg: css`
      font-family: ${fontFamily};
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
    `,
    body02Medium: css`
      font-family: ${fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    `,
    body02Bold: css`
      font-family: ${fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.625rem;
    `,
    body02Reg: css`
      font-family: ${fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    `,
    body03Medium: css`
      font-family: ${fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    `,
    body03Bold: css`
      font-family: ${fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    `,
    body03Reg: css`
      font-family: ${fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    `,
    body04Bold: css`
      font-family: ${fontFamily};
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.375rem;
    `,
    body04Reg: css`
      font-family: ${fontFamily};
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
    `,
    body05Bold: css`
      font-family: ${fontFamily};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
    `,
    body05Reg: css`
      font-family: ${fontFamily};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    `,
    body06Bold: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
    `,
    body06Reg: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
    `,
    caption01Bold: css`
      font-family: ${fontFamily};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
    `,
    caption01Reg: css`
      font-family: ${fontFamily};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    `,
    caption02Reg: css`
      font-family: ${fontFamily};
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.8125rem; /* 130% */
    `,
  },
  highlight: {
    highlight01: css`
      font-family: ${fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.625rem; /* 144.444% */
      text-decoration-line: underline;
    `,
    highlight02: css`
      font-family: ${fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem; /* 150% */
      text-decoration-line: underline;
    `,
    highlight03: css`
      font-family: ${fontFamily};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem; /* 142.857% */
      text-decoration-line: underline;
    `,
    highlight04: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem; /* 138.462% */
      text-decoration-line: underline;
    `,
    highlight05: css`
      font-family: ${fontFamily};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem; /* 133.333% */
      text-decoration-line: underline;
    `,
  },
  label: {
    label01Bold: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
    `,
    label01Reg: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
    `,
    label02Bold: css`
      font-family: ${fontFamily};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
    `,
    label02Reg: css`
      font-family: ${fontFamily};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    `,
    label03Bold: css`
      font-family: ${fontFamily};
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.8125rem;
    `,
    label03Reg: css`
      font-family: ${fontFamily};
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.8125rem;
    `,
  },
};

const devices = {
  mobile: '800px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1920px',
};

const maxWidth = '800px';

// PC 스타일 우선 적용. PC 스타일에서는 media query를 사용하지 않음.
// 모바일 사이즈 스타일 적용(~ 800px)(레거시)
const setMobileStyleLegacy = (style: SerializedStyles) => css`
  @media screen and (max-width: ${devices.mobile}) {
    ${style}
  }
`;

// 모바일 사이즈 스타일 적용(~ 768px)
const setMobileStyle = (style: SerializedStyles) => css`
  @media screen and (max-width: ${devices.sm}) {
    ${style}
  }
`;

// 태블릿 사이즈 스타일 적용(~ 1024px)
const setTabletStyle = (style: SerializedStyles) => css`
  @media screen and (max-width: ${devices.md}) {
    ${style}
  }
`;

// PC 사이즈 스타일 적용(1024px ~ 1280px)
const setPCStyle = (style: SerializedStyles) => css`
  @media screen and (max-width: ${devices.lg}) {
    ${style}
  }
`;

// 최대 PC 사이즈 범위 이상 스타일 적용(1920px ~)
const setMaxStyle = (style: SerializedStyles) => css`
  @media screen and (min-width: ${devices.xl}) {
    ${style}
  }
`;

const theme = {
  colors,
  font,
  fontFamily,
  fontV3,
  devices,
  maxWidth,
  setTabletStyle,
  setPCStyle,
  setMobileStyle,
  setMaxStyle,
  setMobileStyleLegacy,
};

export default theme;
