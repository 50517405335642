import { useMemo } from 'react';
import {
  ApolloClient,
  from,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  fromPromise,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';
import { SERVER_ERROR_MESSAGE, STORAGE_KEY } from '@common/values';
import { REGISTER_ANONYMOUS_USER } from '@api/user';
import { SERVER_ERROR_CODE } from '@common/values';
import {
  clearUserToken,
  setUserToken,
  setUserTokenWithoutClear,
} from '@common/utils';
import { isDevelopment } from '@common/utils';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const httpLink: HttpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SERVER_URI,
});

const authLink: ApolloLink = setContext((_, { headers }) => {
  if (typeof window === 'undefined') {
    return headers;
  }

  const token = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      token: token || '',
      platform: 'Web',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (isDevelopment()) {
      if (graphQLErrors) {
        console.log('graphQLErrors: ', graphQLErrors, 'operation:', operation);
      }
      if (networkError) {
        console.log('networkError: ', networkError, 'operation:', operation);
      }
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err.message.includes(SERVER_ERROR_CODE.UNAUTHORIZED) &&
          err.message.includes(SERVER_ERROR_MESSAGE.AUTH_TOKEN)
        ) {
          return fromPromise(registerAnonymousUser())
            .filter((value) => Boolean(value))
            .flatMap((token) => {
              setUserToken({ token: token ?? '' });
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  token: token || '',
                  platform: 'Web',
                },
              });

              return forward(operation);
            });
        }

        // 회원탈퇴 시 token비우고 홈화면으로 이동
        if (err.message.includes(SERVER_ERROR_MESSAGE.USER_LEFT)) {
          clearUserToken();

          fromPromise(registerAnonymousUser())
            .filter((value) => Boolean(value))
            .flatMap((token) => {
              setUserTokenWithoutClear({ token: token ?? '' });
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  token: token || '',
                  platform: 'Web',
                },
              });

              return forward(operation);
            });

          window.location.href = '/';
        }
      }
    }
  },
);

export const registerAnonymousUser = async () => {
  if (!apolloClient) return;

  const result = await apolloClient.mutate({
    mutation: REGISTER_ANONYMOUS_USER,
  });

  return result?.data?.registerAnonymousUser.token;
};

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'ChapterComment':
            return `ChapterComment:${responseObject.ccId}`;
          case 'UserStoryHistory':
            return `UserStoryHistory:${responseObject.userStoryHistoryId}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
      typePolicies: {
        Query: {
          fields: {
            listStoryByWebV3: {
              keyArgs: [
                'data',
                [
                  'uiSectionType',
                  'weekday',
                  'sorting',
                  'genre',
                  'sortOption',
                  'playType',
                ],
              ],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            searchStoryByWebV3: {
              keyArgs: ['data', ['filter']],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            getStoryV3: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
            listChapterCommentV3: {
              keyArgs: [
                'data',
                [
                  'storyId',
                  'chapterId',
                  'parentId',
                  'orderBy',
                  'onlySystemLanguageMatched',
                ],
              ],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listNotice: {
              keyArgs: [],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listChapterByClient: {
              keyArgs: ['data', ['storyId']],
              merge(existing = { list: [] }, incoming) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listWeeklyStoryRankingV3: {
              keyArgs: ['data', ['playType']],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listUserStoryHistoryV3: {
              keyArgs: [],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listUserLikeStoryBundle: {
              keyArgs: [],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
            listStoryForEpubContestWinner: {
              keyArgs: [],
              merge(
                existing = {
                  list: [],
                },
                incoming,
              ) {
                return {
                  ...incoming,
                  list: [...existing.list, ...incoming.list],
                };
              },
            },
          },
        },
      },
    }),
  });
};

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    // SSR을 통해 fetch된 초기 데이터와 기존 client에 캐시되어있던 데이터를 병합
    const existingCache = _apolloClient.extract();

    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  // SSR, SSG 요청 시 매번 새로운 apollo client를 만들어 return
  if (typeof window === 'undefined') return _apolloClient;

  // client에서 요청 시에는 apolloClient를 한 번만 생성
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: any },
) => {
  pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  return pageProps;
};

export const useApollo = (pageProps: any) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
};

export const getContextByLocale = (locale: string | undefined = 'ko') => {
  let token: string | undefined;

  if (locale === 'ko') {
    if (isDevelopment()) {
      token = process.env.NEXT_PUBLIC_DEV_SSR_TOKEN;
    } else {
      token = process.env.NEXT_PUBLIC_PROD_SSR_TOKEN;
    }
  } else {
    if (isDevelopment()) {
      token = process.env.NEXT_PUBLIC_DEV_GLOBAL_SSR_TOKEN;
    } else {
      token = process.env.NEXT_PUBLIC_PROD_GLOBAL_SSR_TOKEN;
    }
  }

  return {
    headers: {
      token,
      platform: 'Web',
    },
  };
};
