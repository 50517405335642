import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PlayChapterInfo,
  PlayChapter,
  LastPlay,
  ListAchievements,
} from '@customTypes/chapter';
import { getChapterInfo, updateLastPlay } from '../service/ChapterService';

interface IAdInfoStorage {
  [storyId: number]: boolean;
}

interface IChapter {
  playChapterInfo?: PlayChapterInfo;
  isLoading: boolean;
  isRestarting: boolean;
  willUseCoin: boolean;
  isContinue: boolean;
  adInfoStorage: IAdInfoStorage;
  showWholePurchasePopup: boolean;
}

const defaultSate: IChapter = {
  playChapterInfo: undefined,
  isLoading: false,
  isRestarting: false,
  willUseCoin: false,
  isContinue: false,
  adInfoStorage: {},
  showWholePurchasePopup: false,
};

const chatChapterSlice = createSlice({
  name: 'chapter',
  initialState: defaultSate,
  reducers: {
    setPlayChapter(
      state: IChapter,
      action: PayloadAction<{
        playChapterInfo: PlayChapter;
        achievements?: ListAchievements;
      }>,
    ) {
      const chapterInfo: PlayChapter = action.payload.playChapterInfo;
      const achievements: ListAchievements | undefined =
        action.payload.achievements;
      const playChapterInfo: PlayChapterInfo | undefined = chapterInfo
        ? getChapterInfo(chapterInfo, state.isRestarting, achievements)
        : undefined;

      state.playChapterInfo = playChapterInfo || state.playChapterInfo;
    },
    setIsLoading(state: IChapter, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsRestarting(state: IChapter, action: PayloadAction<boolean>) {
      state.isRestarting = action.payload;
    },
    setIsContinue(state: IChapter, action: PayloadAction<boolean>) {
      state.isContinue = action.payload;
    },
    clearPlayChapter(state: IChapter) {
      state.playChapterInfo = undefined;
      state.isLoading = false;
      state.isRestarting = false;
    },
    setUpdateLastPlay(state: IChapter, action: PayloadAction<LastPlay>) {
      const chapterInfo = state.playChapterInfo;
      if (chapterInfo) {
        const playChapterInfo = updateLastPlay(chapterInfo, action.payload);
        state.playChapterInfo = playChapterInfo;
      }
    },
    setAdInfo(
      state: IChapter,
      action: PayloadAction<{ storyId: number; isAdEnabled: boolean }>,
    ) {
      state.adInfoStorage[action.payload.storyId] = action.payload.isAdEnabled;
    },
    setWillUseCoin(state: IChapter, action: PayloadAction<boolean>) {
      state.willUseCoin = action.payload;
    },

    setShowWholePurchasePopup(state: IChapter, action: PayloadAction<boolean>) {
      state.showWholePurchasePopup = action.payload;
    },
  },
});

export const {
  setPlayChapter,
  setIsLoading,
  setIsRestarting,
  setWillUseCoin,
  setIsContinue,
  clearPlayChapter,
  setUpdateLastPlay,
  setAdInfo,
  setShowWholePurchasePopup,
} = chatChapterSlice.actions;
export default chatChapterSlice;
