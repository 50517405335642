import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { touchChallengeContentTypeFilter } from '@/lib/eventManager';
import {
  touchHomeTab,
  touchInteractiveTab,
  touchNovelTab,
  touchChallengeTab,
  touchMoreTab,
} from '@/lib/googleAnalytics';
import { STORAGE_KEY } from '@/common/values';
import { UserInfo } from '@/customTypes/user';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';
import { setUserProperties } from '@/common/utils';

type TCurrentTab =
  | 'home'
  | 'challenge'
  | 'more'
  | 'store'
  | 'interactive'
  | 'web-novel'
  | '';

const Navbar = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<TCurrentTab>('');
  const [userInfo, setUserInfo] = useState<UserInfo>();
  useGetUserQuery({
    onCompleted: (data) => {
      data.getUser &&
        setUserProperties({
          registeredAt: data.getUser?.registeredAt,
          isTester: data.getUser.isTester,
          userId: data.getUser.userId,
          email: data.getUser.email,
          language: data.getUser.systemLanguage?.code ?? undefined,
          countryCode: data.getUser.countryCode,
          isAdult:
            data.getUser.certificatedAt && !data.getUser.isExpiredCertification,
        });
    },
  });

  useEffect(() => {
    if (router.pathname.includes('/challenge')) {
      setCurrentTab('challenge');
      touchChallengeContentTypeFilter();
    } else if (router.pathname.includes('/more')) {
      setCurrentTab('more');
    } else if (router.pathname.includes('/store')) {
      setCurrentTab('store');
    } else if (router.pathname.includes('/interactive')) {
      setCurrentTab('interactive');
    } else if (router.pathname.includes('/webnovel')) {
      setCurrentTab('web-novel');
    } else {
      setCurrentTab('home');
    }
  }, [router.pathname]);

  useEffect(() => {
    const storageInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
    const parsedInfo = storageInfo && JSON.parse(storageInfo);
    setUserInfo(parsedInfo);
  }, [router.locale, router.pathname]);

  return (
    <nav css={navTabs({ currentTab })}>
      <Link href="/">
        <a
          className="tab home"
          onClick={() => {
            touchHomeTab({ tab: 'home' });
          }}
        >
          <i className="tab-icon" />
          <p className="tab-title">{t('home_screen_title_home')}</p>
        </a>
      </Link>
      {!userInfo && (
        <>
          <Link href="/interactive">
            <a
              className="tab interactive"
              onClick={() => {
                touchInteractiveTab();
                touchHomeTab({ tab: 'interactive' });
              }}
            >
              <i className="tab-icon" />
              <p className="tab-title">
                {t('common_screen_title_interactive')}
              </p>
            </a>
          </Link>
          <Link href="/webnovel">
            <a
              className="tab web-novel"
              onClick={() => {
                touchNovelTab();
                touchHomeTab({ tab: 'novel' });
              }}
            >
              <i className="tab-icon" />
              <p className="tab-title">{t('common_screen_title_webnovel')}</p>
            </a>
          </Link>
          <Link
            href={{
              pathname: '/challenge',
              query: {
                genre: 'TOTAL',
                sorting: 'RECOMMENDED',
                showFilter: true,
              },
            }}
          >
            <a
              className="tab challenge"
              onClick={() => {
                touchChallengeTab();
                touchHomeTab({ tab: 'challenge' });
              }}
            >
              <i className="tab-icon" />
              <p className="tab-title">
                {t('common_screen_title_challenge_story')}
              </p>
            </a>
          </Link>
        </>
      )}
      {userInfo &&
        userInfo?.countryCode === 'KR' &&
        userInfo.language === 'ko_KR' && (
          // 한국 > 한국어
          <>
            <Link href="/interactive">
              <a
                className="tab interactive"
                onClick={() => {
                  touchInteractiveTab();
                  touchHomeTab({ tab: 'interactive' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">
                  {t('common_screen_title_interactive')}
                </p>
              </a>
            </Link>
            <Link href="/webnovel">
              <a
                className="tab web-novel"
                onClick={() => {
                  touchNovelTab();
                  touchHomeTab({ tab: 'novel' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">{t('common_screen_title_webnovel')}</p>
              </a>
            </Link>
            <Link
              href={{
                pathname: '/challenge',
                query: {
                  genre: 'TOTAL',
                  sorting: 'RECOMMENDED',
                  showFilter: true,
                },
              }}
            >
              <a
                className="tab challenge"
                onClick={() => {
                  touchChallengeTab();
                  touchHomeTab({ tab: 'challenge' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">
                  {t('common_screen_title_challenge_story')}
                </p>
              </a>
            </Link>
          </>
        )}
      {userInfo &&
        userInfo?.countryCode === 'KR' &&
        userInfo.language !== 'ko_KR' && (
          // 한국 > 영어
          <>
            <Link
              href={{
                pathname: '/challenge',
                query: {
                  genre: 'TOTAL',
                  sorting: 'RECOMMENDED',
                  showFilter: true,
                },
              }}
            >
              <a
                className="tab challenge"
                onClick={() => {
                  touchChallengeTab();
                  touchHomeTab({ tab: 'challenge' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">
                  {t('common_screen_title_challenge_story')}
                </p>
              </a>
            </Link>
            <Link href="/store">
              <a
                className="tab store"
                onClick={() => {
                  // touchHomeTab({ tab: 'store' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">{t('common_screen_title_store')}</p>
              </a>
            </Link>
          </>
        )}
      {userInfo &&
        userInfo?.countryCode !== 'KR' &&
        userInfo?.language === 'ko_KR' && (
          // 그외 > 한국어
          <>
            <Link href="/interactive">
              <a
                className="tab interactive"
                onClick={() => {
                  touchInteractiveTab();
                  touchHomeTab({ tab: 'interactive' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">
                  {t('common_screen_title_interactive')}
                </p>
              </a>
            </Link>
            <Link href="/webnovel">
              <a
                className="tab web-novel"
                onClick={() => {
                  touchNovelTab();
                  touchHomeTab({ tab: 'novel' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">{t('common_screen_title_webnovel')}</p>
              </a>
            </Link>
          </>
        )}
      {userInfo &&
        userInfo?.countryCode !== 'KR' &&
        userInfo?.language !== 'ko_KR' && (
          // 그외 > 한국어 아님
          <>
            <Link href="/store">
              <a
                className="tab store"
                onClick={() => {
                  // touchHomeTab({ tab: 'store' });
                }}
              >
                <i className="tab-icon" />
                <p className="tab-title">{t('common_screen_title_store')}</p>
              </a>
            </Link>
          </>
        )}
      <Link href="/more">
        <a
          className="tab more"
          onClick={() => {
            touchMoreTab();
            touchHomeTab({ tab: 'more' });
          }}
        >
          <i className="tab-icon" />
          <p className="tab-title">{t('more_screen_title_more')}</p>
        </a>
      </Link>
    </nav>
  );
};

const navTabs = ({ currentTab }: { currentTab: TCurrentTab }) => css`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${theme.maxWidth};
  height: 54px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${theme.colors.gray200};
  background: ${theme.colors.white};
  z-index: 10;

  .tab {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tab-icon {
      width: 24px;
      height: 24px;
      background: 50% 50% no-repeat;
    }

    .tab-title {
      margin-top: 6px;
      color: ${theme.colors.gray900};
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .tab.home {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_home_line.svg');
    }
  }

  .tab.challenge {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_challenge_story_line.svg');
    }
  }

  .tab.more {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_more_line.svg');
    }
  }

  .tab.store {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_store_line.svg');
    }
  }

  .tab.interactive {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_interactive_line.svg');
    }
  }

  .tab.web-novel {
    .tab-icon {
      background-image: url('/assets/icon_tabbar_story_line.svg');
    }
  }

  ${currentTab === 'store' &&
  `
    .tab.store {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_store_fill.svg');
      }
    }
  `}

  ${currentTab === 'home' &&
  `
    .tab.home {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_home_fill.svg');
      }
    }
  `}

  ${currentTab === 'challenge' &&
  `
    .tab.challenge {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_challenge_story_fill.svg');
      }

      .tab-title {
        color: ${theme.colors.gray900};
      }
    }
  `}

  ${currentTab === 'more' &&
  `
    .tab.more {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_more_fill.svg');
      }

      .tab-title {
        color: ${theme.colors.gray900};
      }
    }
  `}

${currentTab === 'interactive' &&
  `
    .tab.interactive {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_interactive_fill.svg');
      }

      .tab-title {
        color: ${theme.colors.gray900};
      }
    }
  `}

${currentTab === 'web-novel' &&
  `
    .tab.web-novel {
      .tab-icon {
        background-image: url('/assets/icon_tabbar_story_fill.svg');
      }

      .tab-title {
        color: ${theme.colors.gray900};
      }
    }
  `}
`;

export default Navbar;
